/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Muchachos = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
        Muchachos.navigations.init();
        Muchachos.videos.init();
      },
      scroll: function () {
        // JavaScript to be fired on scroll event
      },
      resize: function () {
        // JavaScript to be fired on resize event
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        Muchachos.el.body.addClass("ready");
        Muchachos.videos.init();
        Muchachos.animations.init();
        Muchachos.compatibility.init();
      },
    },
    // Home page
    homepage: {
      init: function () {
        // JavaScript to be fired on home page
      },
      resize: function () {
        // JavaScript to be fired on home page resize event
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },

    el: {
      // Basics
      html: $("html"),
      body: $("body"),
      header: $('body > header[role="banner"]'),

      // Logo
      logo: $(".logo .site-logo"),

      // Forms
      allInputs: $("input, textarea"),

      // Utils
      hidden: $(".hidden"),
    },

    navigations: {
      init: function () {
        Muchachos.navigations.mobile();
        Muchachos.navigations.products();
      },

      mobile: function () {
        $(".hamburger").click(function () {
          $(this).toggleClass("is-active");
          Muchachos.el.html.toggleClass("menu-is-open");
        });
      },

      products: function () {
        $("li.menu-item-has-children").hover(
          function () {
            if (window.innerWidth >= 992) {
              Muchachos.el.html.addClass("menu-is-open");
              Muchachos.el.body.addClass("menu-is-open");
            }

            // Adjust menu position on scroll to avoid empty-gap
            /*
					$scroll = document.documentElement.scrollTop;
				  	$menu = $('.menu-item-has-children[class*="product"] .sub-menu');
				  	$menu.css('transform', 'translateY('+ -$scroll + 'px)');
				  	$menu.css('bottom', -$scroll);
*/
          },
          function () {
            if (window.innerWidth >= 992) {
              Muchachos.el.html.removeClass("menu-is-open");
              Muchachos.el.body.removeClass("menu-is-open");
            }

            // Reset our adjustments
            /*
				    $menu = $('.menu-item-has-children[class*="product"] .sub-menu');
				    $menu.css('transform', 'translateY(0px)');
				    $menu.css('bottom', 0);
*/
          }
        );
      },
    },

    videos: {
      init: function () {},
    },

    animations: {
      init: function () {
        Muchachos.animations.reveal();
      },
      reveal: function () {
        // Init ScrollReveaul
        window.sr = ScrollReveal({
          reset: false,
          duration: 750,
          distance: "4rem",
          scale: 1,
          delay: 100,
          easing: "ease-in-out",
        });

        sr.reveal(".reveal");
        sr.reveal(".reveal-with-vfactor", { viewFactor: -0.2, delay: 450 });
        sr.reveal(".reveal-with-delay", { duration: 400 }, 150);
      },
    },

    compatibility: {
      init: function () {
        Muchachos.compatibility.ie();
      },

      ie: function () {
        // Fix for blend-mode and filters on .background-overlay
        //window.getComputedStyle(document.body).backgroundBlendMode;
        var supportsMixBlendMode = window.getComputedStyle(
          document.body
        ).mixBlendMode;
        if (typeof supportsMixBlendMode == "undefined") {
          $(".background-overlay, .products .product .product-item").each(
            function () {
              $(this).addClass("force-grey-scale");
              $(this).find("img").gray();
            }
          );
        }
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Muchachos;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },

    // Fire resize JS
    resizeEvents: function () {
      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm, "resize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "resize");
    },
    // Fire resize JS
    scrollEvents: function () {
      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm, "scroll");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "scroll");
    },
  };

  // Throttles for resource-intensive events
  var resizeTimer, scrollTimer;

  // Events
  $(document).ready(UTIL.loadEvents);

  $(window).resize(function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      UTIL.resizeEvents();
    }, 50);
  });

  $(window).scroll(function (e) {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      UTIL.scrollEvents();
    }, 50);
  });

  $(window).on("scroll touchmove", function () {
    //Animated header
    $("header").toggleClass("scroll", $(document).scrollTop() > 0);
  });

  $("custom-featherlight").featherlight({ resetCss: true });

  /**
   * Smooth Scroll
   */

  // Add smooth scrolling to all links
  $("a").on("click", function (event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $("html, body").animate(
        {
          scrollTop: $(hash).offset().top - 130,
        },
        800,
        function () {
          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        }
      );
    } // End if
  });

  // $("a[href*='#']:not([href='#'])").click(function () {
  //   if (
  //     location.pathname.replace(/^\//, "") ==
  //       this.pathname.replace(/^\//, "") &&
  //     location.hostname == this.hostname
  //   ) {
  //     var target = $(this.hash);
  //     target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
  //     if (target.length) {
  //       $("html,body").animate(
  //         {
  //           scrollTop: target.offset().top - 130, //offsets for fixed header
  //         },
  //         1000
  //       );
  //       return false;
  //     }
  //   }
  // });

  // //Executed on page load with URL containing an anchor tag.
  // if ($(location.href.split("#")[1])) {
  //   var target = $("#" + location.href.split("#")[1]);
  //   if (target.length) {
  //     $("html,body").animate(
  //       {
  //         scrollTop: target.offset().top - 130, //offset height of header here too.
  //       },
  //       1000
  //     );
  //     return false;
  //   }
  // }

  /**
   * Jobs filter tabs
   */

  function filter(termValue) {
    if (termValue == null) {
      termValue = "all";
    }

    $(".tab-filter").each(function () {
      $(this)
        .children(".filter-item")
        .each(function () {
          var elemTerm = $(this).attr("data-term");
          elemTerm = elemTerm.split(",");

          if (termValue === "all" || elemTerm.includes(termValue)) {
            // Show
            $(this).show();
          } else {
            // Hide
            $(this).hide();
          }
        });
    });
  }

  $(".location").on("click", function () {
    var termValue = $(this).val();
    filter(termValue);
    setTab(termValue);
    console.log("coucou");
  });

  /**
   * Tabs navigation
   */

  var originalURL = window.location.href.split("#location");
  var currentTab = originalURL[1];

  var navigation = $(".component-tabs__nav");

  if (currentTab) {
    var tabNumber = currentTab.split("-");
    setTab(tabNumber[1]);
  } else {
    filter("quebec");
  }

  function setTab(target) {
    var parent = $("[data-target=" + target + "]");

    navigation.children().removeClass("active");
    parent.addClass("active");

    filter(target);

    var urlPathArr = window.location.href.split("#");
    console.log(urlPathArr);
    window.location.assign(urlPathArr[0] + "#location-" + target);
  }

  /**
   * Job details toggle
   */

  $(".job-details").on("click", function () {
    var dataJob = $(this).attr("data-job");
    console.log(dataJob);
    $("." + dataJob).slideToggle();
    // $("." + dataJob).toggleClass("open");

    $("[data-job-column=" + dataJob + "]").toggleClass("open");
  });

  // setBoxHeight();

  $(document).ready(function () {
    setBoxHeight();
  });

  $(window).on("resize", function () {
    setBoxHeight();
  });

  // Set content height
  function setBoxHeight() {
    var boxesHeight = [];
    var heighestHeight;

    if (window.innerWidth >= 992) {
      $(".cta-jobs--column-item").each(function () {
        boxesHeight.push($(this).outerHeight(false));
      });
      heighestHeight = Math.max.apply(Math, boxesHeight);
      $(".cta-jobs--column-item").css({ height: heighestHeight + "px" });
    }
  }
})(jQuery); // Fully reference jQuery after this point.
